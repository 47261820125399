<template>
  <div class="box">
    <section>
      <div>
        <div class="has-text-right">
          <b-button type="is-success"
                    icon-left="eye"
                    @click="viewFile(document.id)">
            Visualizar Archivo
          </b-button>
          <b-button type="is-info"
                    icon-left="edit"
                    v-if="document.can_edit"
                    @click="goToEdit()">
            Editar Documento
          </b-button>
          <b-button v-if="document.can_nullify" type="is-danger"
                    icon-left="ban"
                    @click="execNullifyModal">
            Anular Documento
          </b-button>
          <b-button v-if="document.can_restore" type="is-success"
                    icon-left="trash-restore"
                    @click="execRestoreModal">
            Restaurar Documento
          </b-button>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column">
          <div class="columns">
            <div class="column is-12">
                  <div class="columns">
                    <div class="column">
                      <b-field label="Carpeta de Documento">
                        <b-input :value="document.document_category_name" type="text" readonly></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column">
                      <b-field label="Tipo de documento">
                        <b-input :value="document.document_types_name!==undefined ? document.document_types_name : '' " type="text" readonly></b-input>
                      </b-field>
                    </div>
                  </div>
                  <div class="columns">
                    <div v-if="visible_number" class="column">
                      <b-field label="N° Documento">
                          <b-input :value="document.resolution" type="text" readonly></b-input>
                      </b-field>
                    </div>
                    <div v-if="visible_date" class="column">
                      <b-field label="Fecha Documento">
                        <b-input :value="formatDate(document.document_date)" type="text" readonly></b-input>
                      </b-field>
                    </div>
                    <div v-if="visible_year" class="column">
                      <b-field label="Año Documento">
                        <b-input :value="document.year" type="text" readonly></b-input>
                      </b-field>
                    </div>
                    <div class="column">
                      <b-field label="¿El documento está firmado?">
                        <b-radio-button v-model="document.signed"
                                        :native-value="true"
                                        type="is-success is-light is-outlined"
                                        disabled>
                          <b-icon icon="check-circle"></b-icon>
                          <span>Si</span>
                        </b-radio-button>
                        <b-radio-button v-model="document.signed"
                                        :native-value="false"
                                        type="is-danger is-light is-outlined"
                                        disabled>
                          <b-icon icon="times-circle"></b-icon>
                          <span>No</span>
                        </b-radio-button>
                      </b-field>
                    </div>
                  </div>
<!--                  <div class="columns">
                    <div class="column">
                      <b-field label="Url carpeta Documento">
                          <b-input expanded :value="document.documentation_link" type="text" readonly></b-input>
                        <p class="control">
                          <b-button tag="a" :href="document.documentation_link" target="_blank" icon-left="external-link-alt" type="is-link">Link</b-button>
                        </p>
                      </b-field>
                    </div>
                  </div>-->
                  <!--<div class="columns">
                    <div class="column">
                      <b-field label="Google ID Documento">
                        <b-input :value="document.google_id" type="text" readonly :visible="false"></b-input>
                      </b-field>
                    </div>
                  </div>-->
                  <div class="columns">
                    <div v-if="visible_description" class="column">
                      <b-field label="Nombre adicional del documento">
                          <b-input :value="document.description" type="text" readonly></b-input>
                      </b-field>
                    </div>

                  </div>
                  <div class="columns">
                    <div  class="column">
                      <h3 class="is-size-6 has-text-weight-bold">Nomenclatura de descarga del archivo
                        <b-tooltip
                            label="Esta nomenclatura representa el nombre con el cual será descargado el archivo, además es el nombre como esta guardado en el repositorio."
                            position="is-bottom"
                            size="is-medium"
                            multilined>

                          <b-icon
                              pack="fas"
                              icon="info-circle"
                              size="is-small"
                              type="is-info">
                          </b-icon>
                        </b-tooltip>
                      </h3>
                      <b-field label=" ">
                        <b-input expanded :value="document.name" type="text" readonly></b-input>
                      </b-field>
                    </div>
                  </div>

                  <div class="columns">
                    <div class="column">
                      <b-field label="Etiquetas">
                        <b-taginput
                            disabled
                            v-model="document.tags"
                            autocomplete
                            :allow-new="true"
                            field="name"
                            icon="tag"
                            placeholder="Palabras clave">
                        </b-taginput>
                      </b-field>
                    </div>
                  </div>
            </div>
          </div>
      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

      <!-- MODALES -->
        </div>
      </div>
      <b-modal
          v-model="showPdf"
          :can-cancel="true"
          :destroy-on-hide="true"
          aria-modal
          aria-role="dialog"
          has-modal-card
          trap-focus
          :width="2000"
      >
        <template #default="props">
          <pdf_view
              :src="src"
              @close="props.close"
          ></pdf_view>
        </template>

      </b-modal>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import {formatDate} from "../../packs/utilities";
import pdf from 'vue-pdf';
import Pdf_view from "../performances/pdf_view.vue"
export default {
  name: "show_document",
  data() {
    return{
      isLoading: false,
      canCancel: ['escape'],
      document:{
        document_state_id: 1,
        tags_attributes: []
      },
      document_types: [],
      document_categories: [],
      tags:[],
      isLoadingMultiselect: false,
      currentPage:1,
      filtered_tags: [],
      isNullifyActive: false,
      showPdf: false,
      src:null,
      visible_year: false,
      visible_date: true,
      visible_number: true,
      visible_description: true,

    }
  },
  props:['userId','documentId', "can_edit", "can_nullify"],
  created() {
    this.initialize()
  },
  components:{
    Pdf_view,
    pdf,
  },
  methods : {
    goToEdit() {
      window.location.href = "/documents/" + this.documentId + "/edit"
    },
    formatDate,
    initialize() {
      this.isLoading = true
      axios
          .get("/documents/" + this.documentId + ".json")
          .then(res => {
            this.document = res.data
            this.changeDocumenType()
            this.isLoading = false;
          }).catch(error => {
        console.log(error);
        this.isLoading = false;
      })
    },
    getFilteredTags(text) {
      this.filtered_tags = this.tags.filter((option) => {
        return option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },
    execNullifyModal() {
      this.$buefy.dialog.confirm({
        title: 'Anular Documento',
        message: '¿Está seguro que desea <b>anular</b> el documento? Esta acción lo dejará inválido.<br>',
        confirmText: 'Anular Documento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete("/documents/" + this.document.id + "/nullify-document.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Documento anulado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == '') {
                  window.location.href = history.back()
                } else {
                  window.location.href = document.referrer
                }
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Documento no pudo ser anulado</b><br>' + error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    execRestoreModal() {
      this.$buefy.dialog.confirm({
        title: 'Restaurar Documento',
        message: '¿Está seguro que desea <b>restaurar</b> el documento? Esta acción lo dejará válido.<br>',
        confirmText: 'Restaurar Documento',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .put("/documents/" + this.document.id + "/restore-document.json")
              .then(res => {
                this.$buefy.dialog.alert({
                  message: 'Documento restaurado correctamente',
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })
                if (document.referrer == '') {
                  window.location.href = history.back()
                } else {
                  window.location.href = document.referrer
                }
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: '<b>Documento no pudo ser restaurado</b><br>' + error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },
    viewFile(fileId) {
      this.isLoading = true;
      axios
          .get("/documents/" + fileId + "/view-document", {
            responseType: "blob",
          })
          .then(async res => {
            const file = new File([new Blob([res.data])], "test");
            const buffer = await file.arrayBuffer();
            this.src = new Uint8Array(buffer);
            this.showPdf = true
            this.isLoading = false
          })
          .catch(e => {
            console.log(e)
            this.isLoading = false
            this.$buefy.dialog.alert({
              message: "ERROR al previsualizar",
              type: "is-danger",
              hasIcon: true
            })
          })

    },
    changeDocumenType() {
      var tipo = ""
      if (this.document.document_type != null) {
        tipo = this.document.document_types_initials
        console.log(tipo)
        if (tipo == "ACTACF_SO" || tipo == "ACTACF_SE" || tipo == "M_ACTACF_SO" || tipo == "M_ACTACF_SE" || tipo == "ACTACD" || tipo == "ACTAID" || tipo == "ACTAVIME" || tipo == "ACTAPOST") {

          this.visible_year = false
          this.visible_date = true
          if (tipo == "M_ACTACF_SO" || tipo == "M_ACTACF_SE") {
            this.visible_description = true
          }
          else {
            this.visible_description = false
          }
        }
        else if (tipo == "CRIT" || tipo == "MAN" || tipo == "POL" || tipo == "CONV" || tipo == "NORM" || tipo == "PRT" || tipo == "EST" || tipo == "REGL" || tipo == "RES" || tipo == "DECR" || tipo == "ORD" || tipo == "LEY" || tipo == "CIR") {
          this.visible_year = true
          this.visible_date = false
          this.visible_description = true
          if (tipo == "CRIT" || tipo == "MAN" || tipo == "POL" || tipo == "CONV" || tipo == "NORM" || tipo == "PRT" || tipo == "EST") {
            this.visible_number = false
          } else {
            this.visible_number = true
          }
        }
        else if (tipo == "PCR_RC" || tipo == "FORM") {
          this.visible_year = false
          this.visible_date = false
          this.visible_description = true
          if (tipo == "PCR_RC") {
            this.visible_number = true
          } else {
            this.visible_number = false
          }

        }
        else {
          this.visible_year = false
          this.visible_date = true
        }
      }

    }

  }

}

</script>
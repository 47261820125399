<template>
  <div>
    <div v-if="!hideUpdate">
      <div class="columns" >

        <div class="column" v-if=" services_types.length>0">
          <b-field label="Tipo de servicio">
              <multiselect v-model="service_type"
                           :options="services_types.map(type => type.id)"
                           :custom-label="opt => services_types.find(x => x.id == opt).name"
                           placeholder="Seleccione tipo de servicio"
                           selectLabel=" "
                           selectedLabel=" "
                           deselectLabel=" "
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                    No se encontraron elementos.
                </span>
              </multiselect>
          </b-field>
        </div>



        <div class="column">
          <b-field  label="Código" >
            <b-input v-model="code" placeholder="Ej: USA2156_FING" ></b-input>
          </b-field>
        </div>


        <div class="column">
          <b-field  label="Nombre del servicio" >
            <b-input v-model="nameFilter" placeholder="Ej: Herramientas De Trabajo Colaborativo" ></b-input>
          </b-field>
        </div>                       
        
      </div>
      <div class="columns" >

        <div class="column" v-if=" units.length>0">
          <b-field label="Unidad">
              <multiselect v-model="unit"
                           :options="units.map(type => type.id)"
                           :custom-label="opt => units.find(x => x.id == opt).name"
                           placeholder="Seleccione unidad"
                           selectLabel=" "
                           selectedLabel=" "
                           deselectLabel=" "
                           :allow-empty="false">
                <template v-slot:noOptions>
                  No existen datos
                </template>
                <span slot="noResult">
                    No se encontraron elementos.
                </span>
              </multiselect>
          </b-field>
        </div>


        <div class="column">
          <b-field label="Año de inicio de servicio" >
            <multiselect v-model="year"
                         :options="years"
                         placeholder="Seleccione año"
                         selectLabel="Presione para seleccionar"
                         selectedLabel="Seleccionado"
                         deselectLabel="No se puede deseleccionar"
                         :allow-empty="false">
              <template v-slot:noOptions>
                No existen datos
              </template>
              <span slot="noResult">
                          No se encontraron elementos.
                      </span>
            </multiselect>
          </b-field>
        </div>




      </div>
      <div class="columns">
        <div class="column has-text-right">          
          <b-button
                    class="button right-button button-clear-filter"
                    icon-left="eraser"
                    @click="clearFilter()">
            Limpiar Filtros
          </b-button>
          <b-button type="is-info"
                    icon-left="search"
                    class="button right-button is-info"
                    @click="updateServicesFilter()">
            Buscar
          </b-button>

        </div>
      </div>
      <div class="columns has-text-right">
        <div class="column">
          <b-switch v-model="cardLayout">Formato tarjeta (sin filtros ni búsqueda)
               <b-tooltip
                   label="Al presionar sobre algún nombre de cabecera de la tabla, se ordenarán los datos según este criterio"
                   multilined
                   position="is-left">
                    <b-icon
                        pack="fas"
                        icon="info-circle"
                        type="is-info">
                    </b-icon>
                </b-tooltip></b-switch>
        </div>
        
      </div>

      <div class="columns">
        <div class="column is-centered">
          <b-message type="is-info" has-icon v-model="isActive">
            {{message}}
          </b-message>
        </div>
      </div>


    </div>  
    <b-table
        :data="servicios"
        :paginated=true
        per-page=10
        striped
        hoverable
        :card-layout="cardLayout"
        :current-page="currentPage"
        :pagination-simple=false
        pagination-position="bottom"
        default-sort-direction="desc"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort="servicio.start_date"
        aria-next-label="Siguiente"
        aria-previous-label="Anterior"
        aria-page-label="Página"
        aria-current-label="Página actual"
        :opened-detailed="defaultOpenedDetails"
        detailed
        detail-key="code"
        @details-open="(row) => $buefy.toast.open(`Expandiendo ${row.name}`)"
        :show-detail-icon="showDetailIcon"
        >
        <b-table-column
          field= 'sdt_service_type'
          label= 'Tipo de Servicio'
          width="15em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.sdt_service_type }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'code'
          label= 'Código'
          width="10em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.code}}
        </template>
      </b-table-column>

      <b-table-column
          field= 'name'
          label= 'Nombre servicio'
          width="25em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.name }}
        </template>
      </b-table-column>

      <b-table-column
          field= 'unit'
          label= 'Unidad'
          width="20em"
          searchable
          v-if="!hideUpdate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.unit }}
        </template>
      </b-table-column>


      <b-table-column
          field= 'award_year'
          label= 'Año creacion'
          width="10em"
          searchable
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
          {{ props.row.award_year }}
        </template>
      </b-table-column>
      

      
      <b-table-column
          field= 'start_date'
          label= 'Fecha de inicio'
          width="10em"
          searchable
          :custom-search="searchPublicationDate"
          sortable>
        <template
            slot="searchable"
            slot-scope="props">
          <b-input
              v-model="props.filters[props.column.field]"
              icon="search"
              size="is-small" />
        </template>
        <template v-slot="props">
            {{ props.row.start_date._isValid ? new Date(props.row.start_date).toLocaleDateString("es-CL"): '' }}
        </template>
      </b-table-column>
      

      <b-table-column
          label="Acciones"
          width="10em"
          v-slot="props">
        <b-button type="is-primary"
                  icon-left="eye"
                  @click="goService(props.row)">
        </b-button>
        <b-button type="is-danger"
                  icon-left="ban"
                  v-if="canNullify=='true'"
                  @click="execNullifyModal(props.row)">
        </b-button>
      </b-table-column>

      <template slot="detail" slot-scope="props">
        <article class="media">
          <div class="media-content">
            <div class="content">
              <p>
                <strong>Jefe de proyecto: </strong>
                <span>{{props.row.projectManagment.person_name || "Sin definir"}}</span>
              </p>
              <p v-if="props.row.partner_entity">
                <strong>Cliente: </strong>
                <span>{{props.row.partner_entity}}</span>
              </p>

            </div>
          </div>
        </article>
      </template>

      <template slot="empty" slot-scope="props">
        <div class="columns is-centered">
          <div class="column is-6 is-centered has-text-centered">
            <b-message type="is-danger">
              No se encontraron resultados para el año seleccionado.
            </b-message>
          </div>
        </div>
      </template>


    </b-table>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>

  </div>
</template>
<script>
import {searchDate} from "../../packs/utilities";
import axios from "axios";
export default {
  props:[
      'servicios','hideUpdate','cargoPrincipal', 'message', 'unidad', 'canNullify'
  ],
  data(){
    return{
      isLoading:false,
      currentPage:1,
      cardLayout:false,
      defaultOpenedDetails: [1],
      showDetailIcon: true,
      year: new Date().getFullYear(),
      years:[],
      services_types: [],
      units: [],
      unit: '',
      isNullifyActive: false,
      code: '',
      keepFirst: false,
      openOnFocus: false,
      service_type: null,
      clearable: false,
      nameFilter: '',
      isActive:false,
    }
  },
  created(){
    this.isLoading = true
    this.years = this.generateArrayOfYears();
    return axios.get("/sdt_services/form-data.json")
        .then(res => {
          this.units = res.data.unidades;
          this.services_types = res.data.project_types;
          this.isLoading=false;
        })
        .catch(e => {
          this.isLoading=false;
          console.log(e);
        });

  },
  methods:{
    goService(service){
      window.location.href = "/sdt_services/"+service.id+"?type="+service.table;
    },
    generateArrayOfYears() {
      var max = new Date().getFullYear()
      var min = 1980
      var years = []
      years.push("Todo")
      for (var i = max; i >= min; i--) {
        years.push(i)
      }
      return years
    },
    async updateServicesFilter(){
      var datos = {
        year: this.year,
        code: this.code,
        name: this.nameFilter,
        type: this.service_type,
        unit: this.unit
      }

      await this.$emit('updateServicesFilter',datos)
      this.isActive=true;
    },
    clearFilter(){
      this.year = new Date().getFullYear();
      this.code = '';
      this.nameFilter = '';
      this.service_type = null;
      this.unit = '';        

    },
    searchPublicationDate(row,input){
      return searchDate(row.start_date,input)
    },
    execNullifyModal(row){
      let ruta = ""
      if (row.table === "projects"){
        ruta = "/projects/" + row.id + "/nullify_project.json"
      }
      else{
        ruta = "/sdt_services/" + row.id + "/nullify_service.json"
      }

      let titulo = ""
      let msj = ""
      let msjError = ""
      let msjExito = ""
      let conf = ""
      let tipo = 0
      titulo = "Anular Servicio de EFUSACH"
      msj = '¿Está seguro que desea <b>anular</b> el servicio de EFUSACH? Esta acción lo dejará inválido.<br>'
      msjError = '<b>Servicio de EFUSACH no pudo ser anulado</b><br>'
      msjExito = "Servicio de EFUSACH anulado correctamente"      
      this.$buefy.dialog.confirm({
        title: titulo,
        message: msj,
        confirmText: titulo,
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.isNullifyActive = true
          this.isLoading = true
          axios
              .delete(ruta)
              .then(res => {
                this.$buefy.dialog.alert({
                  message: msjExito,
                  duration: 5000,
                  type: 'is-success',
                  canCancel: ["escape", "outside"]
                })

                var index = this.servicios.indexOf(row)
                this.servicios.splice(index, 1)
                this.$emit('elementoAnulado', row);
                this.isLoading = false;
                this.isNullifyActive = false;
              }).catch(error => {
            console.log(error)
            this.$buefy.dialog.alert({
              message: msjError+error.response.data,
              duration: 5000,
              type: 'is-danger',
              canCancel: ["escape", "outside"]
            })
            this.isLoading = false;
            this.isNullifyActive = false;
          })
        }
      })
    },

  },
  watch:{
    isNullifyActive: function () {
      if (!this.isNullifyActive) {
        this.updateServicesFilter()
      }
    },
  },
  computed:{

  }
}
</script>
<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }


</style>
<template>
  <section>
    <div class="columns">
      <div class="column">
        <div class="mb-2" v-if="Object.keys(errores_validacion).length !== 0">
          <b-message type="is-danger" has-icon>
            <div v-for="(item,index) in errores_validacion" :key='index'>
              <div v-if="item">{{ item[0] }}</div>
            </div>
          </b-message>
        </div>
      </div>
    </div>
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveTransaction)">
        <div class="box"> 
          <b-steps
                v-model="activeStep"
                :animated="isAnimated"
                :rounded="isRounded"
                :has-navigation="hasNavigation"
                :icon-prev="prevIcon"
                :icon-next="nextIcon"
                :label-position="labelPosition"
                :mobile-mode="mobileMode">
            <b-step-item step="1" label="General" :clickable="isStepsClickable">
              <div class="box">
                <h1 class="title has-text-centered">General  [1/2]</h1>
                <div class="columns">
                  
                  <div class="column" v-if="unidades.length>0">
                    <b-field label="Unidad*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="transaction.unit_id"
                                     :options="unidades.map(type => type.id)"
                                     :custom-label="opt => unidades.find(x => x.id == opt).name"
                                     placeholder="Seleccione Unidad"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     :disabled="!can_create_people"
                                     @select="getCodes"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Código de EFUSACH asociado*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="transaction.sdt_surplus_id"
                                     :options="codes.map(type => type.id)"
                                     :custom-label="opt => codes.find(x => x.id == opt).code"
                                     placeholder="Seleccione código de EFUSACH"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel=""
                                     deselectLabel=""
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  
                </div>
                <div class="columns">                
                  <div class="column" v-if="tipos_finanzas.length>0">
                    <b-field label="Categoría de bien*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <multiselect v-model="transaction.sdt_transaction_type_id"
                                     :options="tipos_finanzas.map(type => type.id)"
                                     :custom-label="opt => tipos_finanzas.find(x => x.id == opt).name"
                                     placeholder="Seleccione Categoría de bien"
                                     selectLabel="Presione para seleccionar"
                                     selectedLabel="Seleccionado"
                                     deselectLabel="No se puede deseleccionar"
                                     @select="showOther"
                                     :allow-empty="false">
                          <template v-slot:noOptions>
                            No existen datos
                          </template>
                          <span slot="noResult">
                              No se encontraron elementos.
                          </span>
                        </multiselect>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field label="Descripción de otro*" v-if="show_other == true" >                            
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-autocomplete v-model="transaction.other_id"
                                        :keep-first="keepFirst"
                                        :open-on-focus="openOnFocus"
                                        :data="other_types"
                                        field="description"
                                        empty="Vacio"
                                        placeholder="Ej: Tramitaciones legales"
                                        @typing="searchOtherTypes"
                                        @select="option => {if (option) {transaction.other_id = option.id} }"
                                        :clearable="clearable">
                          <template slot-scope="props">
                            {{ props.option.description }}
                          </template>
                        </b-autocomplete>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  
                </div>




                <div class="columns">
                  <div class="column">
                    <b-field label="Proveedor (ingrese por lo menos 3 letras) ">
                        <b-autocomplete v-model="entidad_name"
                                        :keep-first="keepFirst"
                                        :open-on-focus="openOnFocus"
                                        :data="partner_entities"
                                        field="name"
                                        empty="Vacio"
                                        placeholder="Ej: ANID"
                                        @typing="searchPartnerEntities"
                                        @select="option => {if (option) {transaction.partner_entity_id = option.id} }"
                                        :clearable="clearable">
                          <template slot-scope="props">
                            {{ props.option.show_name ? props.option.show_name : props.option.name}} {{ props.option.rut ? '[ RUT: ' + props.option.rut.rut + ']' : '' }}
                          </template>
                        </b-autocomplete>
                    </b-field>
                  </div>
                  <div class="column is-narrow">
                    <br>
                    <b-field label=" ">
                      <b-tooltip label="Si la entidad que desea agregar no existe, la puede agregar presionando el botón +"
                                 position="is-bottom">
                        <b-button class="is-primary is-outlined" @click="addEntity()">
                          <b-icon icon="plus"></b-icon>
                        </b-button>
                      </b-tooltip>
                    </b-field>
                  </div>
                </div>
              </div>
            </b-step-item>
            <b-step-item step="2" label="Documentos" :clickable="isStepsClickable">
              <div class="box">
                <h1 class="title has-text-centered">Adjuntar Documentos  [2/2]</h1>
                <h2 class="has-text-centered new-h2">Documentación de Boleta o Factura</h2>
                <br><br>
                <div class="columns">
                  <b-field label="Seleccione Boleta o Factura*:">
                    <b-radio class="column" v-for="item in tiposAnexo" :key="item.id" v-model="tipoAnexo" :native-value="item">
                        {{capitalizarTexto(item.name)}}
                    </b-radio>
                  </b-field>
                </div>

                <div class="columns">
                  <div class="column">
                    <b-field :label="tipoAnexo && tipoAnexo.name ? 'Código de '+capitalizarTexto(tipoAnexo.name) : 'Código de Boleta/Factura'" >   
                      <ValidationProvider rules="required|max:200" v-slot="{errors}">
                        <b-input type="text" maxlength="200" placeholder="Ej: 002651" v-model="transaction.code"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>
                  
                  <div class="column">
                    <b-field label="Fecha de pago*">
                      <ValidationProvider rules="required" v-slot="{errors}">
                        <b-datepicker
                            v-model="transaction.payment_date"
                            icon="calendar-alt"
                            trap-focus
                            locale="es-ES"
                            editable
                            placeholder="Formato de Fecha: dd/mm/aaaa">
                        </b-datepicker>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>

                  <div class="column">
                    <b-field label="Monto Bruto Total*" >                            
                      <ValidationProvider rules="required|numeric|positive|min_value:0|max_value:99999999999|max:11" v-slot="{errors}">
                        <b-input type="text" maxlength="11" placeholder="Ej: 500000" v-model="transaction.amount"></b-input>
                        <span class="validation-alert">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </b-field>
                  </div>


                </div>
                <div class="columns">
                  <div class="column has-text-centered is-centered">
                    <b-message class="is-inline-block is-centered" type="is-warning" title="¡Importante!" has-icon aria-close-label="Close message">
                        Extensiones de archivo permitido: PDF, DOC, DOCX, XLSX, XLS.
                        <br />
                        Tamaño máximo de archivo permitido: <b> 10 MB</b>
                    </b-message>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-field class="file is-primary is-centered" :class="{'has-name': !!file2}">
                        <b-upload v-model="file2" class="file-label is-centered" @input="AddFile">
                            <span class="file-cta">
                                <b-icon class="file-icon" icon="upload"></b-icon>
                                <span class="file-label">Subir {{ tipoAnexo && tipoAnexo.name ? capitalizarTexto(tipoAnexo.name) : 'Boleta o Factura' }}</span>
                            </span>
                            <span class="file-name" v-if="file2">
                                {{ file2.name }}
                            </span>
                        </b-upload>
                      </b-field>
                      <span class="validation-alert has-text-centered is-centered">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>
                




                <h2 class="has-text-centered new-h2">Formulario asociado</h2>
                <br>

                <div class="columns">  
                  <div class="column">
                    <b-field label="Formulario asociado*">
                        <ValidationProvider rules="required" v-slot="{ errors }">
                            <multiselect v-model="transaction.tipo"
                                           :options="tipos.map(type => type.id)"
                                           :custom-label="opt => tipos.find(x => x.id == opt).name"
                                           placeholder="Seleccione Formulario asociado"
                                           selectLabel=""
                                           selectedLabel=""
                                           deselectLabel=""
                                           :allow-empty="true">
                                <template v-slot:noOptions>
                                    No existen datos
                                </template>
                                <span slot="noResult">
                                    No se encontraron elementos.
                                </span>
                            </multiselect>
                            <span class="validation-alert">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </b-field>
                  </div>
                  <div class="column">
                    <br>
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <b-field class="file is-primary" :class="{'has-name': !!file1}">
                        <b-upload v-model="file1" class="file-label" @input="AddFile">
                            <span class="file-cta">
                                <b-icon class="file-icon" icon="upload"></b-icon>
                                <span class="file-label">Subir Formulario Asociado</span>
                            </span>
                            <span class="file-name" v-if="file1">
                                {{ file1.name }}
                            </span>
                        </b-upload>
                      </b-field>
                      <span class="validation-alert">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                </div>

              </div>


            </b-step-item>
            <template
                #navigation="{previous, next}">
              <div class="columns is-pulled-right" >
                <div class="column">
                  <b-button
                      outlined
                      icon-left="chevron-left"
                      :disabled="previous.disabled"
                      @click.prevent="previous.action">
                  </b-button>
                  <b-button
                      outlined
                      icon-right="chevron-right"
                      :disabled="next.disabled"
                      @click.prevent="next.action">
                  </b-button>

                </div>
              </div>
            </template>
          </b-steps>
          <br>
          <br>
          <br>
          <div class="columns">
            <div class="column">
              <div class="is-pulled-left">
                <button v-if="modalCancel" class="button left-button" type="button" @click="$emit('close')">Cerrar</button>
              </div>
              <div class="is-pulled-right">
                <b-button class="button is-primary" icon-left="save" native-type="submit" @click="checkValidation()">Guardar transacción</b-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false"></b-loading>


    <b-modal
        v-model="isAddModalEntityActive"
        :destroy-on-hide="true"
        width="50%"
        :can-cancel="canCancel">
      <template #default="props">
        <modal_partner_entity
            query-type="update"
            modal-header="Añadir nueva entidad"
            @close="props.close"
            @clicked="onClickChildEntity"></modal_partner_entity>
      </template>
    </b-modal>

  </section>
</template>

<script>
import axios from "axios";
import moment from "moment";
import modal_partner_entity from "../../moduloVIME/agreements/new/modal_partner_entity";
export default {
  name: "NewTransactionServicioForm",
  components:{modal_partner_entity},
  data() {
    return {
      keepFirst: false,
      hasNavigation: true,
      openOnFocus: false,
      clearable: false,
      errores_validacion: {},
      transaction:{},
      tipos_finanzas: [],
      procesos: [],
      proceso: null,
      tipo: null,
      unidades:[],
      codes: [],
      categorias_finanzas: [],
      category_transaction:null,
      documentos:[],
      isLoading:false,
      other_types:[],
      files: [],
      isDisabled: true,
      is_validated:false,
      show_other: false,
      isAnimated: true,
      isRounded: true,
      isStepsClickable: true,
      hasNavigation: true,
      activeStep: 0,
      prevIcon: 'chevron-left',
      nextIcon: 'chevron-right',
      labelPosition: 'bottom',
      mobileMode: 'minimalist',
      tipos: [],
      tiposAnexo: [],
      currentPage: 1,
      people: [],
      person_selected: null,
      persona_sel: null,
      search_rut: "",
      search_dv: "",
      search_name: "",
      search_last_name: "",
      search_surname: "",
      file1: null,
      file2: null,
      filesRecibidos: [],
      tipoAnexo: null,
      canCancel: ['escape'],
      entidad_name: null,
      partner_entities:[],
      isAddModalEntityActive: false,
      can_create_people: false,
    }
  },
  watch: {
    documentos: {
      handler(newFiles) {
        // Aquí actualizamos el arreglo 'documentos' cuando se seleccionan nuevos archivos
        this.files = newFiles.map(file => {
          const existingDocumento = this.files.find(doc => doc.file.name === file.name);
          if (existingDocumento) {
            return {
              file: file,
              amount: existingDocumento.amount || null,
            };
          } else {
            return {
              file: file,
              amount: null,
            };
          }
        });
      },
      deep: true
    }
  },
  props:[
      'modalHeader','userId','queryType','transactionId','modalCancel', "unidadPrincipal", 'hideHeader',
  ],
  async created(){
    await this.getTransactionTypes();
    this.transaction.unit_id = this.unidadPrincipal.id;
    await this.getCodes();
    
    if (this.queryType=='update'){
      await this.getTransaction();
      await this.getDocuments();
    }
  },
  methods:{
    async saveTransaction(){
      let vm = this;
      await vm.checkValidation();
      if (vm.is_validated){
        vm.isLoading=true;
        const formData = new FormData();        
        vm.files.forEach((documento, index) => {
          formData.append(`file${index}`, documento);
          if (index == 0){
            formData.append(`typeFile${index}`, vm.transaction.tipo);
          }
          else {
            formData.append(`typeFile${index}`, vm.tipoAnexo.id);
          }
          if(vm.queryType=='update'){
            formData.append(`originalFiles${index}`, vm.filesRecibidos[index].id); 
          }
        });
        
        formData.append('transaction',JSON.stringify(vm.transaction));
        if (vm.queryType=="update"){
          axios
            .put("/sdt_transactions/"+vm.transaction.id+".json", formData)
            .then((res) => {
              
              this.$buefy.dialog.alert({
                message: "La transacción fue actualizada con éxito.<br/>",
                confirmText: "Ok",            
              });
              res.data.payment_date = moment(res.data.payment_date, ["YYYY-MM-DD","DD-MM-YYYY"]);
              vm.$emit("clickedUpdate",res.data);
              vm.$emit('close');
              vm.isLoading = false;
            })
            .catch(e => {
              vm.errores_validacion = e.response.data
              vm.isLoading=false;
          });   
        }
        else{
          axios
          .post("/sdt_transactions.json", formData)
          .then((res) => {
            vm.isLoading = false;
            this.$buefy.dialog.alert({
              message: "La transacción fue guardada correctamente <br/>",
              confirmText: "Ok",            
            });
            window.location.href = '/sdt_transactions/'+res.data["id"]
            vm.isLoading=false;
          })
          .catch(e => {
            vm.errores_validacion = e.response.data
            vm.isLoading=false;
        });   


        }

      }


    },
    async getTransactionTypes(){
      let vm = this;
      vm.isLoading=true;
      await axios.get("/sdt_transactions/new/item.json")
        .then(res => {
          vm.tipos_finanzas = res.data.tipos_finanzas;
          vm.unidades = res.data.unidades;
          vm.can_create_people = res.data.can_create_people;
          vm.tipos = res.data.tipos_documentos.filter(elemento => elemento.name.startsWith('FORMULARIO'));
          vm.tiposAnexo =  res.data.tipos_documentos.filter(elemento => elemento.name.startsWith('ANEXO'));

          vm.tiposAnexo = vm.tiposAnexo.map(elemento => ({
                                id: elemento.id,
                                name: elemento.name.replace(/^ANEXO \s*/, ''),
                                initials: elemento.initials
                              }));
          vm.transaction.proceso = res.data.proceso;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    async getCodes() {
      let vm = this;
      vm.isLoading=true;      
      await axios.get("/sdt_surpluses/unit-surplus/"+vm.transaction.unit_id+".json")
        .then(async (res) => {
          vm.transaction.sdt_surplus_id = null;
          vm.codes = res.data;          
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    async selectProcess(){
      let vm = this;
      vm.isLoading=true;
      await  axios.get("/document_type_names/document-names.json",{params:{id:vm.transaction.proceso, all: true}})
        .then(res => {
          vm.tipos = [];
          vm.transaction.tipo = null;
          vm.tipos = res.data;
          vm.isDisabled = false;
          vm.isLoading = false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

    },

    capitalizarTexto(texto) {
      return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
    },
    onDeleteFile() {
        this.file = null;
    },
    searchOtherTypes(name){
      if(name == null   || name.length == 0){
        this.position = null
      }
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/sdt_transaction_types/types-search.json", { params: {
            description: name,
            type: vm.transaction.sdt_transaction_type_id
          } })
          .then(res => {
            vm.other_types = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },

    async checkValidation() {
      this.is_validated = false;
      const valid = await this.$refs.observer.validate()
      if (!valid) {
        this.$buefy.dialog.alert({
          message: 'Faltan algunos campos o presentan errores de formato. Por favor revisar los campos a rellenar.',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
      else{
        this.is_validated = true;
      }
    },
    getTotalFileSize(){
      let totalSize= 0;
      if (this.file !=null) {
        totalSize = this.file.size; 
      }
      return Math.round(totalSize*100/1000000)/100;
    },
    showOther(){
          
      if(this.tipos_finanzas.find(x => x.id == this.transaction.sdt_transaction_type_id).name.includes("OTRAS ") || this.tipos_finanzas.find(x => x.id == this.transaction.sdt_transaction_type_id).name.includes("OTROS ") ){
        this.show_other = true;
      }
      else
      {
        this.show_other = false;
      }

    },
    async selectCategory(){
      let vm = this;
      vm.isLoading=true;
      await  axios.get("/sdt_transaction_types/origin-type/"+vm.category_transaction+".json")
        .then(res => {
          vm.tipos_finanzas = [];
          vm.transaction.sdt_transaction_type_id = null;
          vm.tipos_finanzas = res.data;
          vm.isDisabled = false;
          vm.isLoading = false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });

    },
    async getTransaction(){
      let vm = this;
      vm.isLoading=true;
      await axios
          .get("/sdt_transactions/"+vm.transactionId+".json")
          .then(res => {
            vm.transaction = res.data;
            vm.transaction.payment_date = new Date(moment(vm.transaction.payment_date).format('MM/DD/YYYY'));            
            if(vm.transaction.partner_entity != "")
            {
              vm.entidad_name = vm.transaction.partner_entity.name;
              vm.transaction.partner_entity_id = vm.transaction.partner_entity.id;
            }
            vm.isLoading=false;
          })
          .catch(e => {
            console.log(e);
            vm.isLoading=false;
          });
    },
    async getDocuments() {
      let vm = this;
      vm.isLoading=true;
      await axios.get("/sdt_transactions/documents/"+vm.transactionId+".json")
        .then(res => {
          let filesRec = res.data.documents;
          vm.file1 = filesRec[0];
          vm.transaction.number_code = filesRec[0].description;
          vm.transaction.tipo = filesRec[0].document_type.id;
          vm.file2 = filesRec[1];
          vm.files = [vm.file1, vm.file2];
          vm.filesRecibidos = res.data.documents;
          let anexo = {};
          anexo.id = filesRec[1].document_type.id;
          anexo.name = filesRec[1].document_type.name.replace(/^ANEXO \s*/, '');
          anexo.initials = filesRec[1].document_type.initials;
          vm.tipoAnexo = anexo;
          vm.isLoading=false;
        })
        .catch(e => {
          vm.isLoading=false;
          //console.log(e);
        });
    },
    AddFile(){
      this.files = [this.file1, this.file2];
    },

    onClickChildEntity(value){
      this.isLoading = true
      this.searchPartnerEntities(value.name)
      this.entidad_name = value.name
      this.transaction.partner_entity_id = value.id
      this.isLoading = false
    },
    searchPartnerEntities(name){
      if(name == null   || name.length == 0){
        this.position = null
      }
      if (name.length<3) {
        this.partner_entities = []
        return
      }
      let vm = this;
      vm.isLoading=true;
      axios
          .get("/sdt_transactions/entities-search.json", { params: {
            name: name
          } })
          .then(res => {
            vm.partner_entities = res.data;
            vm.isLoading = false;
          })
          .catch(error => {
            console.log(error)
            vm.isLoading=false;
          })
    },
    addEntity(){
      this.isAddModalEntityActive = true;
    },



  }
}
</script>

<style scoped>

  .right-button{
    margin-left: auto;
  }
  .left-button{
    margin-right: auto;
  }
  .new-h2 {
    font-size: 24px;
    font-weight: bold;
  }


</style>